import React, { useCallback, useLayoutEffect, useRef, useState } from 'react'
import { useLocation } from '@reach/router'
import { throttle } from 'lodash'
import { Box, Button, Flex } from '@chakra-ui/react'
import { BrandLogo } from './brand'
import Container from './container'
import Nav from './nav'
import Sticky from './sticky'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { FaWhatsapp } from 'react-icons/fa'
import MobileNav from './mobile-nav'

const Header = (props) => {

	const isRootPath = useLocation().pathname === '/'

	const ref = useRef()
	const { height = 0 } = ref.current?.getBoundingClientRect() ?? {}
	const [hasScrolled, setHasScrolled] = useState(!isRootPath)
	const throttledHasScrolled = useCallback(throttle(() => setHasScrolled(window.scrollY > 70), 300), [hasScrolled])

	useLayoutEffect(() => {
		if(isRootPath) {
			throttledHasScrolled()
			document.addEventListener('scroll', throttledHasScrolled)
			return() => {
				document.removeEventListener('scroll', throttledHasScrolled)
			}
		}
	}, [hasScrolled])

	const { contentfulMenu: {
		links: navLinks,
		ctaText, 
		ctaLink
	} } = useStaticQuery(graphql`
		query {
			contentfulMenu(slug: {eq: "cabecalho"}) {
				...menuLinks
				ctaText
				ctaLink
			}
		}
	`)

	return(
		<Box 
			as="header"
			position="fixed"
			left="0"
			right="0"
			top="0"
			zIndex="20"
			backgroundColor={hasScrolled ? 'white' : 'transparent'}
			transition="background-color .2s ease"
			boxShadow={hasScrolled ? "sm" : null}
		>
			<Sticky />
			<Container>
				<Flex 
					alignItems="center"
					justifyContent="space-between"
				>
					<Flex>
					<Link to="/">
						<BrandLogo 
							width={{base: hasScrolled ? 24 : 36, md: hasScrolled ? 36 : 52}} 
							height={{base: hasScrolled ? 12 : 24, md: hasScrolled ? 16 : 32}}
							transition="width .2s ease, height .2s ease" 
						/>
						</Link>
						<Nav navLinks={navLinks} ml={16} display={{base: "none", md: "flex"}} />
					</Flex>
					{hasScrolled && 
						<Button 
							as="a"
							target="_blank"
							href={ctaLink}
							borderRadius="sm" 
							leftIcon={<FaWhatsapp />} 
							colorScheme="whatsapp" 
							backgroundColor="#25D366" 
							color="white"
							fontWeight="bold"
							_hover={{textDecoration: "none", backgroundColor: "whatsapp.600"}}
							display={{base: "none", md: "flex"}}
						>
							{ctaText}
						</Button>
					}
					<MobileNav display={{base: "block", md: "none"}} cta={{text: ctaText, link: ctaLink}} navLinks={navLinks} />
				</Flex>
			</Container>
		</Box>
	)
}

export default Header