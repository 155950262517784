import { Box, Button, HStack, Link, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react'
import { Link as GatsbyLink } from 'gatsby'
import React from 'react'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'

const Nav = ({navLinks, ...rest}) => {

	return(

		<HStack spacing={8} {...rest} as="nav">
			{navLinks.map(({slug, title, links}, index) => (
				<Box key={`menu-${index}`}>
					{!links && 
						<Link 
							as={GatsbyLink} 
							to={`/${slug}`} 
							key={index} 
							color="ocean.700" 
							fontWeight="semibold" 
							fontSize="lg"
						>
							{title}
						</Link>
					}
					{links && 
						<Menu>
							{({isOpen}) => (
								<>
									<MenuButton 
										as={Button} 
										rightIcon={isOpen ? <FaChevronUp /> : <FaChevronDown/>} 
										variant="link" 
										color="ocean.700" 
										fontWeight="semibold" 
										fontSize="lg"
										_expanded={{color: "ocean.700"}}
									>
										{title}
									</MenuButton>
									<MenuList>
										{links.map((subMenu, index) => (
											<MenuItem key={`submenu-${index}`} as={GatsbyLink} _hover={{textDecoration: "none"}} to={`/${slug}/${subMenu.slug}`}>
												{subMenu.title}
											</MenuItem>
										))}
									</MenuList>
								</>
							)}
						</Menu>
					}
				</Box>
			))}
		</HStack>

	)
}

export default Nav