import { Box, Heading, SimpleGrid, Link, Text, VStack, UnorderedList, ListItem, Icon, Divider, HStack } from '@chakra-ui/react'
import { Link as GatsbyLink, useStaticQuery, graphql } from 'gatsby'
import React from 'react'
import { FaEnvelope, FaFacebookSquare, FaInstagram, FaWhatsapp } from 'react-icons/fa'
import { formatWhatsappNumber } from '../utils'
import BrandLogo from './brand'
import Container from './container'
import FixedWhatsapp from './fixed-whatsapp'
import { SiteAuthor } from './site-author'

const Footer = (props) => {

	const { services, pages, siteInfo } = useStaticQuery(graphql`
		query {
			services: contentfulMenu(slug: {eq: "servicos"}) {
				...menuLinks
			}
			pages: contentfulMenu(slug: {eq: "paginas"}) {
				...menuLinks
			}
			siteInfo: contentfulSitemetadata {
				address
				whatsapp
				email
				instagram
				facebook
			}
		}
	`)

	return(
		<>
		<Box py={8} backgroundColor="brand.700">
			<Container>
				<SimpleGrid columns={{base:1, md: 4}} spacing={8} py={4} >
					<Box>
						<BrandLogo color="white" width={32} height={16} />
					</Box>
					<Box>
						<Heading color="white" fontSize="md" pb={2}>Serviços</Heading>
						<UnorderedList color="white" >
							{services.links.map(({slug, title}, index) => (
								<ListItem fontSize="sm" key={`${slug}-${index+1}`}>
									<Link color="white" as={GatsbyLink} to={`/servicos/${slug}`}>{title}</Link>
								</ListItem>
							))}
						</UnorderedList>
					</Box>
					<Box>
						<Heading color="white" fontSize="md" pb={2}>A Climatec</Heading>
						<UnorderedList color="white" >
								{pages.links.map(({slug, title}, index) => (
									<ListItem fontSize="sm" key={`${slug}-${index+1}`}>
										<Link color="white" as={GatsbyLink} to={`/${slug}`}>{title}</Link>
									</ListItem>
								))}
						</UnorderedList>
					</Box>
					<Box>
						<Heading color="white" fontSize="md" pb={2}>Fale conosco</Heading>
						<VStack alignItems="start" color="white">
							<HStack as={Link} href={`https://wa.me/55${formatWhatsappNumber(siteInfo.whatsapp)}`} isExternal>
								<Icon as={FaWhatsapp} boxSize={6}  />
								<Text>{siteInfo.whatsapp}</Text>
							</HStack>
							<HStack as={Link} href={`mailto:${siteInfo.email}`}>
								<Icon as={FaEnvelope} boxSize={6} />
								<Text>{siteInfo.email}</Text>
							</HStack>
							<HStack as={Link} href={`https://facebook.com/${siteInfo.facebook}`} isExternal>
								<Icon as={FaFacebookSquare} boxSize={6} />
								<Text>@{siteInfo.facebook}</Text>
							</HStack>
							<HStack as={Link} href={`https://instagram.com/${siteInfo.instagram}`} isExternal>
								<Icon as={FaInstagram} boxSize={6} />
								<Text>@{siteInfo.instagram}</Text>
							</HStack>
						</VStack>
					</Box>
				</SimpleGrid>
				<Divider my={8} borderColor="brand.600" />
				<Box color="brand.200" textAlign="center">
						<Text>Climatec &copy; { new Date().getFullYear() } - Todos direitos reservados. <br/> {siteInfo.address} </Text>
				</Box>
			</Container>
			<FixedWhatsapp />
		</Box>
		<SiteAuthor source="climatec" backgroundColor="brand.800" />
		</>
	)
}

export default Footer