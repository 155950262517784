import React from 'react'
import { Box } from '@chakra-ui/react'
import Header from './header'
import Footer from './footer'

const Layout = ({children, ...rest}) => {
	return(
		<>
		<Header />
		<Box as="main">{children}</Box>
		<Footer />
		</>
	)
}

export default Layout