import { Box, chakra, Circle, Icon, Tooltip } from '@chakra-ui/react'
import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import { FaWhatsapp } from 'react-icons/fa'
import { formatWhatsappNumber } from '../utils'

const FixedWhatsapp = (props) => {

	const { contentfulSitemetadata: { whatsapp } } = useStaticQuery(graphql`
		query {
			contentfulSitemetadata {
				whatsapp
			}
		}
	`)

	return(

		<Box 
			position="fixed"
			zIndex="sticky"
			bottom="0"
			right="0"
			padding={{base: 4, md: 16}}
		>
			<Tooltip gutter={16} placement="top" hasArrow label="Precisa de ajuda?" colorScheme="whatsapp">
				<chakra.a href={`https://wa.me/55${formatWhatsappNumber(whatsapp)}`} target="_blank" role="group">
					<Circle 
						backgroundColor="#25D366" 
						p={{base: 2, md: 4}}
						borderWidth={2}
						borderColor="white"
						boxShadow="md"
						transition="all .2s ease-in-out"
						_groupHover={{
							transform: "scale(1.1)"
						}}
					>
						<Icon boxSize={{base: 8, md: 12}} as={FaWhatsapp} color="white" />
					</Circle>
				</chakra.a>
			</Tooltip>
		</Box>

	)

}

export default FixedWhatsapp