import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, useDisclosure, VStack } from '@chakra-ui/react'
import { Link } from 'gatsby'
import React, { useRef } from 'react'
import { FaBars, FaWhatsapp } from 'react-icons/fa'
import BrandLogo from './brand'

const MobileNav = ({navLinks, cta, ...rest}) => {

	const { isOpen, onOpen, onClose } = useDisclosure()
	const btnRef = useRef()

	return(
		<Box {...rest}>
		<Button size="sm" colorScheme="brand" ref={btnRef} onClick={onOpen} rightIcon={<FaBars />}>MENU</Button>
		<Drawer zIndex="overlay" isOpen={isOpen} onClose={onClose}>
			<DrawerOverlay>
				<DrawerContent>
					<DrawerCloseButton />
					<DrawerHeader>
						<BrandLogo width={24} height={12} />
					</DrawerHeader>
					<DrawerBody>
					<Accordion allowToggle>
						{
							navLinks.map(({slug, title, links}, index) => (
								
									<AccordionItem key={`accordion-item-${index+1}`} >
										<AccordionButton 
											to={links ? "" : `/${slug}`} 
											as={links ? AccordionButton : Link} 
											variant="ghost" 
											color="brand.700"
											_hover={{textDecoration: "none"}}
										>
											{title}
											{links && <AccordionIcon />}
										</AccordionButton>
										{links && 
											<AccordionPanel backgroundColor="blackAlpha.50">
												<Box>
													<VStack>
														{links.map((subMenu, index) => (
															<Button 
																to={`/${slug}/${subMenu.slug}`} 
																as={Link} 
																variant="ghost" 
																colorScheme="brand"
																_hover={{textDecoration: "none"}}
																key={`submenu-${index+1}`}
															>
																{subMenu.title}
															</Button>
														))}
												</VStack>
												</Box>
											</AccordionPanel>
										}
									</AccordionItem>
							))
						}
						</Accordion>
						<Button 
							as="a"
							target="_blank"
							href={cta.link}
							borderRadius="sm" 
							leftIcon={<FaWhatsapp />} 
							fontSize="sm"
							width="100%"
							colorScheme="whatsapp" 
							backgroundColor="#25D366" 
							color="white"
							fontWeight="bold"
							_hover={{textDecoration: "none", backgroundColor: "whatsapp.600"}}
						>
							{cta.text}
						</Button>
					</DrawerBody>
				</DrawerContent>
			</DrawerOverlay>
		</Drawer>
		</Box>
	)
}

export default MobileNav