import { chakra, Flex, HStack, Icon, Text } from '@chakra-ui/react'
import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import { FaFacebookSquare, FaInstagram, FaRegClock, FaWhatsapp } from 'react-icons/fa'
import { formatWhatsappNumber } from '../utils'
import Container from './container'

const Sticky = (props) => {

	const { contentfulSitemetadata: data } = useStaticQuery(graphql`
		query {
			contentfulSitemetadata {
				...allSiteInfo
			}
		}
	`)

	return(

		<Flex
			backgroundColor="brand.500"
			fontSize={{base: "sm", md: "md"}}
		>
			<Container>
				<Flex
					justifyContent="space-between"
				>
					<HStack display={{base: "none", md: "flex"}}>
						<Text color="white" fontWeight="semibold">Siga-nos</Text>
						<chakra.a
							href={`https://facebook.com/${data.facebook}`}
							role="group"
							target="_blank"
						>
							<Icon 
								as={FaFacebookSquare}
								color="white"
								boxSize="1.5rem"
								transition="all .3s ease"
								_groupHover={{
									opacity: '0.8'
								}}
							/>
						</chakra.a>
						<chakra.a
							href={`https://instagram.com/${data.instagram}`}
							role="group"
							target="_blank"
						>
							<Icon 
								as={FaInstagram}
								color="white"
								boxSize="1.5rem"
								transition="all .3s ease"
								_groupHover={{
									opacity: '0.8'
								}}
							/>
						</chakra.a>
					</HStack>
					<HStack spacing={8}>
						<chakra.a
							href={`https://wa.me/55${formatWhatsappNumber(data.whatsapp)}`}
							color="white"
							role="group"
							target="_blank"
						>
							<HStack>
								<Icon 
									as={FaWhatsapp}
									boxSize={{base: 4, md: 6}} 
									transition="all .3s ease"
									_groupHover={{
										opacity: '0.8'
									}}
								/>
								<Text fontWeight="semibold"><chakra.span display={{base: "none", md: "inline"}} >Whatsapp - </chakra.span>{data.whatsapp}</Text>
							</HStack>
						</chakra.a>
							<HStack color="white">
								<Icon 
									as={FaRegClock}
									boxSize={{base: 4, md: 6}} 
									transition="all .3s ease"
									_groupHover={{
										opacity: '0.8'
									}}
								/>
								<Text fontWeight="semibold">{data.openingTime}</Text>
							</HStack>
					</HStack>
				</Flex>
			</Container>
		</Flex>

	)
}

export default Sticky