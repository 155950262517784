import React from "react"
import { Box } from "@chakra-ui/react"

export const Container = (props) => (
  <Box
    w="full"
    py={4}
    mx="auto"
    maxW="1200px"
    px={{ base: 4, md: 8 }}
    {...props}
  />
)

export default Container